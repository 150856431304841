import React from 'react';
import styled, { css } from 'styled-components';

export const ContentBoxCss = css`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.09);
  border: 1px solid #e8e8e8;
  padding: 15px;
  overflow: hidden;

  @media (min-width: 700px) {
    padding: 25px;
  }

  h3 {
    margin-top: 0;
  }
`

const ContentBoxWrapper = styled.div`
  ${ContentBoxCss}
`;

const ContentBox = ({children, className}) => (
  <ContentBoxWrapper className={className}>
    {children}
  </ContentBoxWrapper>
);

export default ContentBox
