import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import HelpCentre from "../../views/help-centre"
import HelpCentreCategory from "../../views/help-centre-category"
import HelpCentreQuestion from "../../views/help-centre-question"
import LearnListing from "../../views/learn-listing"
import LearnArticle from "../../views/learn-article"
import Backup from "../../views/backup"

import PageNotFound from "../../views/errors/404"

function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route
          path="/help-centre/:category/:question"
          component={HelpCentreQuestion}
        />
        <Route
          path="/help-centre/:category"
          exact
          component={HelpCentreCategory}
        />
        <Route path="/help-centre" exact component={HelpCentre} />
        <Route path="/learn/:article" component={LearnArticle} />
        <Route path="/learn" exact component={LearnListing} />
        <Route path="/about-us" exact component={Backup} />
        <Route path="/privacy-notices" exact component={Backup} />
        <Route path="/terms-of-use" exact component={Backup} />
        <Route path="/terms-of-business" exact component={Backup} />
        <Route path="/disclaimer" exact component={Backup} />
        <Route path="/product-documents" exact component={Backup} />
        <Route path="/fund-fact-sheets" exact component={Backup} />
        <Route path="/contact" exact component={Backup} />
        <Route path="/" exact component={HelpCentre} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  )
}

export default AppRouter
