import React from "react"
import styled from "styled-components"

import ContentWrapper from "../content-wrapper"

const FooterWrapper = styled.footer`
  background-color: #475496;
  padding: 26px 0;
  @media (min-width: 700px) {
    padding: 56px 0;
  }
`

// A bug where flex-basis doesn't take into account border box was causing an overflow
// with padding in IE11 - fixed by using calc for the flex-basis
const FooterLinks = styled.div`
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 15px 0;
  margin-bottom: 25px;

  a {
    flex-basis: calc(100% - 15px);
    margin-bottom: 10px;
    padding-right: 15px;
    @media (min-width: 500px) {
      flex-basis: calc(50% - 15px);
    }
    @media (min-width: 1000px) {
      flex-basis: calc(20% - 15px);
    }
    color: white;
  }
`

const FooterParagraph = styled.p`
  color: white;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 10px;
`

const Footer = () => (
  <FooterWrapper>
    <ContentWrapper>
      <FooterLinks>
        <a href="/about-us">About us</a>
        <a href="/privacy-notices">Privacy notices</a>
        <a
          href="https://www.irishlife.ie/website-use-policy/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Cookies policy
        </a>
        <a href="/terms-of-use">Terms of use</a>
        <a href="/terms-of-business">Terms of business</a>
        <a href="/disclaimer">Disclaimer</a>
        <a href="/product-documents">Product documents</a>
        <a href="/fund-fact-sheets">Fund fact sheets</a>
        <a href="/contact">Contact us</a>
      </FooterLinks>
      <FooterParagraph>
        Irish Life Assurance plc is registered in Ireland. Registered Office:
        Irish Life Centre, Lower Abbey Street, Dublin 1. Registered Number:
        152576. Irish Life Assurance plc is regulated by the Central Bank of
        Ireland.
      </FooterParagraph>
      {process.env.REACT_APP_TENANT === "irishlife" && (
        <>
          <FooterParagraph>
            Irish Life Financial Services Limited is registered in Ireland.
            Registered Office: Irish Life Centre, Lower Abbey street, Dublin 1.
            Registered Number: 489221. Irish Life Financial Services Limited is
            regulated by the Central Bank of Ireland.
          </FooterParagraph>
          <FooterParagraph>
            Irish Life Financial Services Limited is tied to Irish Life
            Assurance plc for life and pensions.
          </FooterParagraph>
        </>
      )}
      {process.env.REACT_APP_TENANT === "ptsb" && (
        <>
          <FooterParagraph>
            Permanent TSB plc trading as PTSB is regulated by the Central Bank
            of Ireland.
          </FooterParagraph>
          <FooterParagraph>
            A list of names and personal details of every director of the
            company is available for inspection to the public at the company's
            registered office for a nominal fee. Registered in Ireland.
            Registered number: 222332. Registered office: 56-59 St. Stephen's
            Green, Dublin 2, Ireland.
          </FooterParagraph>
          <FooterParagraph>
            Permanent TSB plc is tied to Irish Life Assurance plc for life and
            pensions products.
          </FooterParagraph>
        </>
      )}
    </ContentWrapper>
  </FooterWrapper>
)

export default Footer
