import React from "react"
import styled from "styled-components"

import PageSection from "../../components/page-section"
import PageTemplate from "../../components/page-template"
import ContentGrid from "../../components/content-grid"

import articles from "../../content/learn-articles/"

const CardWrapper = styled.a`
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.09);
  transition: box-shadow 0.3s ease-in-out;

  cursor: pointer;
  color: #333;

  &:hover {
    text-decoration: none;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
`

const CardImage = styled.img`
  width: 100%;
`

const CardBody = styled.div`
  h2 {
    font-size: 27px;
    font-family: "Assistant-Regular";
  }
  padding: 15px;
  @media (min-width: 700px) {
    padding: 25px;
  }
`

function Card({ title, href, content, imageUrl }) {
  return (
    <CardWrapper href={href}>
      <CardImage src={imageUrl} />
      <CardBody>
        <h2>{title}</h2>
        <div>{content}</div>
      </CardBody>
    </CardWrapper>
  )
}

const LearnListing = ({ match }) => {
  return (
    <PageTemplate>
      <PageSection>
        <ContentGrid>
          {articles.map(({ title, slug, summary, image }) => (
            <Card
              key={slug}
              title={title}
              href={`/learn/${slug}`}
              content={summary}
              imageUrl={image}
            />
          ))}
        </ContentGrid>
      </PageSection>
    </PageTemplate>
  )
}

export default LearnListing
