import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { ContentBoxCss } from "../../components/content-box"
import ContentGrid from "../../components/content-grid"
import PageSection from "../../components/page-section"
import PageTemplate from "../../components/page-template"
import { getHelpCenterQuestionCategories } from "../../utils/contentful"

import blueArrow from "../../assets/arrow-widget.svg"

const Categories = styled.div`
  margin-bottom: 20px;
  @media (min-width: 700px) {
    margin-bottom: 55px;
  }
  h2 {
    font-family: "Assistant-ExtraBold";
    margin-bottom: 25px;
    @media (min-width: 700px) {
      margin-bottom: 50px;
      text-align: center;
    }
  }
`

const Category = styled.a`
  ${ContentBoxCss}

  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: initial;

  p {
    flex-grow: 1;
  }

  p:last-of-type {
    margin-bottom: 10px;
  }

  h3:after {
    content: url(${blueArrow});
    margin-left: 0.5em;
  }

  &:hover {
    text-decoration: none;
  }
`

const ContactUs = styled.span`
  display: inline-block;
  font-size: 15px;
  text-align: center;
  width: 100%;
  a {
    color: #2096f3;
  }
`

const HelpCentre = () => {
  const [categories, setCategories] = useState(null)
  useEffect(() => {
    if (
      navigator.userAgent === "ReactSnap" ||
      document.location.hostname === "localhost"
    ) {
      getHelpCenterQuestionCategories()
        .then((response) => response.categories)
        .then((state) => {
          setCategories(state)
          window.snapSaveState = () => ({ state })
        })
    } else {
      setCategories(window.state)
    }
  }, [])

  if (!categories) return null

  return (
    <PageTemplate>
      <PageSection>
        <Categories>
          <h2>What can we help you with?</h2>
          <ContentGrid>
            {categories.map(({ name, summary = "", slug }) => (
              <Category key={name} href={`/help-centre/${slug}`}>
                <h3>{name}</h3>
                <p>{summary}</p>
              </Category>
            ))}
          </ContentGrid>
        </Categories>
        <ContactUs>
          More questions? Call us on{" "}
          <a href={`tel:${process.env.REACT_APP_SUPPORT_NUMBER}`}>
            {process.env.REACT_APP_SUPPORT_NUMBER}
          </a>{" "}
          or email us at{" "}
          <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
            {process.env.REACT_APP_SUPPORT_EMAIL}
          </a>
        </ContactUs>
      </PageSection>
    </PageTemplate>
  )
}

export default HelpCentre
