import "react-app-polyfill/ie11"
import React from "react"
import { hydrate, render } from "react-dom"

import "./index.css"
import App from "./App"
import { redirectIfNeeded } from "./redirects.js"
import * as serviceWorker from "./serviceWorker"

redirectIfNeeded()

const rootElement = document.getElementById("root")
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}

serviceWorker.unregister()
