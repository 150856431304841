import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../../components/content-wrapper';

import PreviousArrowSVG from './arrow-grey.svg';

const BreadCrumbTrailWrapper = styled.nav`
  margin-top: 25px;
  a {
    color: #2E909D
  }
  @media(min-width: 700px) {
    margin-bottom: -45px;
  }
`;

// `back` is used to display only the single crumb that is set as back
// after '/' must never appear on narrow (but always appears on wide)
// The full crumb will always appear on wider screens
const Crumb = styled.span`
  color: #706b70;
  display: ${({ back }) => back ? 'inline-block' : 'none'}
  font-size: 15px;
  :not(:last-of-type) {
    &:after {
      content: '/';
      display: none;
      margin: 0 5px;
      @media(min-width: 700px) {
        display: inline-block;
      }
    }
  }
  @media(min-width: 700px) {
    display: inline-block;
  }
`

// Used to wrap the text and hide it on narrow, where it is replaced with
// a link called previous
// media queries toggle between this and `Previous`
const AText = styled.span`
  display: none;
  @media(min-width: 700px) {
    display: inline-block;
  }
`

// Used to output the back button
// media queries toggle between this and `AText`
const Previous = styled.span`
  align-items: center;
  color: #706b70;
  display: inline-flex;
  img {
    margin-right: 10px;
  }
  @media(min-width: 700px) {
    display: none;
  }
`

const BreadCrumbTrail = ({ crumbs }) => (
  <BreadCrumbTrailWrapper>
    <ContentWrapper>
      {
        crumbs.map(({ back = false, name, href }) => {
          // Providing back as a property allows the state of the elements to be controlled
          // more cleanly as the CSS to do this was tricky, especially consideing the wrappers
          // and the fact the last link wasn't necessarily the back link
          const crumbContents = back
            ? (
              <React.Fragment key={name}>
                <Previous>
                  <img src={ PreviousArrowSVG } alt=''/>
                  Previous
                </Previous>
                <AText>{name}</AText>
              </React.Fragment>
            ) : name;

          const crumb = href ? <a href={href}>{crumbContents}</a> : <span>{crumbContents}</span>;

          return (<Crumb key={name} back={back}>{crumb}</Crumb>);
        })
      }
    </ContentWrapper>
  </BreadCrumbTrailWrapper>
);

export default BreadCrumbTrail;
