import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../../components/content-wrapper'

const PageSectionWrapper = styled.section`
  padding: 40px 0;

  @media (min-width: 700px) {
    padding: 60px 0;
  }
`

const PageSection = ({ children }) => (
  <PageSectionWrapper>
    <ContentWrapper>
      {children}
    </ContentWrapper>
  </PageSectionWrapper>
);

export default PageSection;
