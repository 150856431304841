import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background: rgb(222, 224, 237);
`

function CookieBanner() {
  return (
    <Container>
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="2f2acb6d-8c6e-4935-b48e-07e7913fef44"
        data-blockingmode="auto"
        type="text/javascript"
      ></script>
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="2f2acb6d-8c6e-4935-b48e-07e7913fef44"
        type="text/javascript"
        async
      ></script>
    </Container>
  )
}

export default CookieBanner
