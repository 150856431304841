import styled from "styled-components"

export const ButtonAsLink = styled.a`
  background: #cfd4fa;
  border-radius: 100px;
  border: none;
  color: #5261ac;
  cursor: pointer;
  display: inline-block;
  font-family: "Assistant-ExtraBold";
  font-size: 15px;
  height: 35px;
  width: 108px;
  line-height: 20px;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
`

export const ButtonAsButton = styled.button`
  appearance: none;
  background: #2e909d;
  border-radius: 22px;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Assistant-ExtraBold";
  font-size: 15px;
  height: 43px;
  padding: 0 20px;
  text-align: center;
  white-space: nowrap;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
`

export default ButtonAsLink
