import a01 from "./01-The-Secret-Sauce.json"
import a02 from "./02-where-to-invest-money-in-ireland.json"
import a03 from "./03-the-beginners-guide-to-investing.json"
import a04 from "./04-putting-your-money-to-work.json"
import a05 from "./05-create-an-investment-plan.json"
import a06 from "./06-diversify-your-investments.json"
import a07 from "./07-does-it-pay-to-keep-money-on-deposit.json"
import a08 from "./08-what-is-smart-invest-and-how-does-it-actually-work.json"
import ptsb08 from "./08Ptsb-what-is-smart-invest-and-how-does-it-actually-work.json"
import a09 from "./09-5-businesswomen-and-the-journey-to-success.json"
import a10 from "./10-how-to-use-investor-psychology.json"
import ptsb02 from "./02Ptsb-where-to-invest-money-in-ireland.json"

const learnPages =
  process.env.REACT_APP_TENANT === "ptsb"
    ? [a01, ptsb02, a03, a05, a06, a04, a07, ptsb08, a09, a10]
    : [a01, a02, a03, a05, a06, a04, a07, a08, a09, a10]

export default learnPages
