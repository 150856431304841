import React from "react"
import styled from "styled-components"
import IrishLifeLogo from "../../assets/irish-life-logo.svg"
import PtsbLogo from "../../assets/ptsb-logo.svg"

const BrandWrapper = styled.div`
  height: 48px;
  position: relative;
`

const SmartInvest = styled.img`
  position: absolute;
  height: 51.281px;
  z-index: 2;
`

const Ptsb = styled.img`
  padding: 8px 12px;
  height: 50px;
`

const Brand = () => (
  <BrandWrapper>
    {process.env.REACT_APP_TENANT === "ptsb" ? (
      <Ptsb src={PtsbLogo} />
    ) : (
      <SmartInvest src={IrishLifeLogo} />
    )}
  </BrandWrapper>
)

export default Brand
