const redirects = [
  {
    from: '/help-centre/investing/are-there-any-restrictions-on-who-can-use-smart-invest',
    to: '/help-centre/about-smart-invest/who-can-open-a-smart-invest-account'
  },
  {
    from: '/help-centre/investing/maps-asset-classes-explained',
    to: '/help-centre/investing/what-are-maps'
  }
]

export function redirectIfNeeded () {
  const matchedRedirect = redirects.find(entry => entry.from === document.location.pathname)
  if (matchedRedirect) {
    document.location = matchedRedirect.to
  }
}