import React from 'react';
import styled from 'styled-components';

import IEFixGridElement from './ie-fix-grid-element';

const ContentGridWrapper = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  margin: 0 auto;

  > * {
    margin: 5px;
    @media (min-width: 700px) {
      margin: 10px;
    }
  }

  @media (min-width: 700px) {
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    -ms-grid-columns: (1fr)[${({ columns }) => columns}];
  }
`;

const ContentGrid = ({
  children,
  className,
  columns = 2
}) => (
  <ContentGridWrapper className={className} columns={columns}>
    { children.map((child, i) => <IEFixGridElement key={i} columns={columns} position={i}>{ child }</IEFixGridElement>) }
  </ContentGridWrapper>
);

export default ContentGrid;
