import React, { useState } from "react"
import styled from "styled-components"
import Brand from "../brand"
import Button from "../button"

import HamburgerSVG from "../../assets/hamburger.svg"

const HeaderWrapper = styled.header`
  background-color: #ffffff;
  height: 48px;
  position: sticky;
  top: 0;
  z-index: 5;
  box-shadow: 0px 0px 20px 0px rgba(82, 97, 172, 0.24);
`

const HeaderContents = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  padding-right: 20px;
`

const ResponsiveMenu = styled.div`
  display: none;

  @media (min-width: 700px) {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
`

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: flex-end;

  a {
    display: block;
    padding: 15px 0;
    margin: 0 1em;
    color: #5261ac;
  }
`

const Sidebar = styled.div`
  display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
  position: absolute;
  background-color: #5261ac;
  height: calc(100vh - 48px);
  width: 100vw;
  top: 48px;
  flex-direction: column;
  align-items: flex-end;
  padding: 1.5em 1em 1em 1em;

  a {
    display: block;
    padding: 1em;
    color: white;
  }
`

const Hamburger = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 0 -15px 0 10px;
  opacity: ${({ menuOpen }) => (menuOpen ? 0.5 : 0.8)};
  padding: 15px;
  transition: 0.1s opacity ease-in;

  &:hover {
    opacity: 1;
  }

  @media (min-width: 700px) {
    display: none;
  }
`

const BrandLink = styled.a`
  flex-grow: 1;
  margin-right: 20px;

  @media (min-width: 700px) {
    flex-grow: 0;
  }
  &:hover {
    text-decoration: none;
  }
`

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenuOpen = () => setMenuOpen(!menuOpen)

  return (
    <HeaderWrapper>
      <HeaderContents>
        <BrandLink href="/">
          <Brand />
        </BrandLink>
        <ResponsiveMenu>
          <Nav>
            <a href="/help-centre">Help centre</a>
            <a href="/learn">Learn</a>
            <a
              href={process.env.REACT_APP_LOGIN_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Log in
            </a>
          </Nav>
        </ResponsiveMenu>
        <Button target="_blank" href={process.env.REACT_APP_START_URL}>
          Get started
        </Button>
        <Hamburger menuOpen={menuOpen} onClick={toggleMenuOpen}>
          <img src={HamburgerSVG} alt={`${menuOpen ? "close" : "open"} menu`} />
        </Hamburger>
        <Sidebar menuOpen={menuOpen}>
          <a href="/help-centre">Help centre</a>
          <a href="/learn">Learn</a>
          <a
            href={process.env.REACT_APP_LOGIN_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Log in
          </a>
        </Sidebar>
      </HeaderContents>
    </HeaderWrapper>
  )
}

export default Header
