const { createClient } = require("contentful")

const host = process.env.REACT_APP_CONTENTFUL_HOST
const space = process.env.REACT_APP_CONTENTFUL_SPACE
const accessToken = process.env.REACT_APP_CONTENTFUL_KEY

const objectMap = (obj, callback) => {
  return Object.entries(obj).reduce(
    (acc, [fieldName, value]) => ({ ...acc, [fieldName]: callback(value) }),
    {}
  )
}

const extractFields = (item) => {
  return objectMap(item.fields, (value) =>
    value.fields ? extractFields(value) : value
  )
}

// Return categories
export const getHelpCenterQuestionCategories = () => {
  const client = createClient({
    space,
    accessToken,
    host,
  })
  return client
    .getEntries({
      content_type: "questionCategory",
      order: "sys.createdAt",
    })
    .then((response) => ({
      categories: response.items.map(extractFields),
    }))
}

// Return questions by category
export async function getHelpCentreQuestions(category) {
  const client = createClient({
    space,
    accessToken,
    host,
  })

  const entries = await client.getEntries({
    content_type: "question",
    "fields.category.sys.contentType.sys.id": "questionCategory",
    "fields.category.fields.slug": category,
    "fields.tenant[in]":
      process.env.REACT_APP_TENANT === "ptsb"
        ? "Only PTSB,Common"
        : "Only Irish Life,Common",
    order: "fields.order",
  })

  const questions = entries.items.map(extractFields)

  return {
    category: entries.includes
      ? extractFields(entries.includes.Entry[0])
      : null,
    questions,
  }
}
