export const pageContent = {
  "/about-us": `## About us 
  ${
    process.env.REACT_APP_TENANT === "ptsb"
      ? `Smart Invest is a digital investing platform focused on making investing simple and accessible. It is provided by Irish Life Financial Services Limited (ILFS), a company in the Irish Life Group.

  PTSB have partnered with ILFS to make this platform available to you and to provide the investment advice.  Permanent TSB plc trading as PTSB is an insurance intermediary and is tied to Irish Life Assurance plc for life and pensions products.  Irish Life Assurance plc is also a company in the Irish Life Group and is one of Ireland’s leading life and pensions companies. Irish Life Assurance provides life insurance, pensions, savings and investment products which are distributed by a number of intermediaries including ILFS and PTSB.`
      : `Smart Invest by Irish Life is a digital investing platform focused on making investing simple and accessible.
  It is a digital advice tool provided by Irish Life Financial Services Limited (ILFS), a company in the Irish Life Group. ILFS is a multi-agency intermediary tied to Irish Life Assurance for life and pensions products. It provides financial advice to help people across Ireland build better financial futures, through both face-to-face and digital advice.  
  Irish Life Assurance plc is also a company in the Irish Life Group and is one of Ireland’s leading life and pensions companies. Irish Life Assurance provides life insurance, pensions, savings and investment products which are distributed by a number of intermediaries including ILFS.`
  } 
  `,
  "/privacy-notices": `## Privacy notices`,
  "/terms-of-use": `## Terms of use`,
  "/terms-of-business": `## Terms of business`,
  "/disclaimer": `## Disclaimer
  All use by you of this website is at your own risk. You assume complete responsibility for, and for all risk of loss resulting from, your downloading and/or using of, or referring to or relying on, Materials, Software, or any other information obtained from your use of this website. You agree that Irish Life and providers of telecommunications and network services to Irish Life will not be liable for damages arising out of your use or your inability to use this website, and you hereby waive any and all claims with respect thereto, and whether based on contract, tort or other grounds.

  This website is available to all users "as is" without any representations or warranties of any kind, either express or implied. Irish Life makes no representations, warranties or undertakings that this website, or the server that makes it available, will be free from defects, including, but not limited to viruses or other harmful elements. Irish Life accepts no liability for any infection by computer virus, bug, tampering, unauthorised access, intervention, alteration or use, fraud, theft, technical failure, error, omission, interruption, deletion, defect, delay, or any event or occurrence beyond the control of Irish Life, which corrupts or affects the administration, security, fairness and integrity or proper conduct of any aspect of this website. There are certain inherent risks in using the Internet and the World Wide Web. Irish Life makes no representations, warranties or undertakings about:
  * Any of the Materials or Software on this website (including without limitation, their accuracy, their completeness or their merchantability, quality or fitness for any particular purpose; or
  * Any content of or information on any other website referred to or accessed by hypertext link through this website or from which this website is referred to or accessed by hypertext link ("third party site"). Irish Life does not endorse or approve the content of any third party site, nor will Irish Life have any liability in connection with any third party site (including but not limited to liability arising out of any allegation that the content of or information on any third party site infringes any law or the rights of any person or entity). No judgement or warranty is made with respect to the accuracy, timeliness or suitability of the content of any third party site, and any and all liability which might arise out of, or in connection with, your use or reliance on the content of or information on, or the performance of, any third party site is excluded.

  Irish Life reserve the right to change the content, presentation, performance, user facilities and availability of any part of this website at its sole discretion, including these terms and conditions of use, and you should check these terms and conditions of use regularly for any changes.
  `,
  "/product-documents": `## Product documents`,
  "/fund-fact-sheets": `## Fund fact sheets`,
  "/contact": `## Contact us: Who can I talk to?
  Our Customer Support team is your one-stop shop for any questions you have about Smart Invest and the FlexInvest plan. 

You can reach our Customer Support team (9:00 – 17:30 Monday to Friday, excluding bank holidays).

Phone: <a>${process.env.REACT_APP_SUPPORT_NUMBER}</a>

Email: <${process.env.REACT_APP_SUPPORT_EMAIL}>
`,
}

export const pageAttachments = {
  "/privacy-notices":
    process.env.REACT_APP_TENANT === "ptsb"
      ? [
          {
            name: "Irish Life Assurance Privacy Notice",
            href: "/pdfs/ILA-privacy-notice.pdf",
          },
          {
            name: "permanent tsb Privacy Notice",
            href: "/pdfs/PTSB-data-privacy-notice.pdf",
          },
        ]
      : [
          {
            name: "Irish Life Assurance Privacy Notice",
            href: "/pdfs/ILA-privacy-notice.pdf",
          },
          {
            name: "Irish Life Financial Services Privacy Notice",
            href: "/pdfs/ILFS-privacy-notice.pdf",
          },
        ],
  "/terms-of-business":
    process.env.REACT_APP_TENANT === "ptsb"
      ? [
          {
            name: "permanent tsb Terms of Business",
            href: "/pdfs/PTSB-terms-of-business.pdf",
          },
          {
            name: "permanent tsb Sustainability Related Disclosures",
            href: "/pdfs/PTSB-sustainability-related-disclosures.pdf",
          },
        ]
      : [
          {
            name: "Irish Life Financial Services Terms of Business",
            href: "/pdfs/terms-of-business-ILFS.pdf",
          },
          {
            name: "Irish Life Financial Services Sustainability Related Disclosures",
            href: "/pdfs/ILFS-sustainability-related-disclosures.pdf",
          },
        ],
  "/terms-of-use":
    process.env.REACT_APP_TENANT === "ptsb"
      ? [
          {
            name: "Smart Invest Terms of Use",
            href: "/pdfs/PTSB-terms-of-use.pdf",
          },
        ]
      : [
          {
            name: "Smart Invest Terms of Use",
            href: "/pdfs/terms-of-use.pdf",
          },
        ],
  "/product-documents":
    process.env.REACT_APP_TENANT === "ptsb"
      ? [
          {
            name: "MAPS Conservative Key Information Document",
            href:  process.env.REACT_APP_AWS_PRODUCT_DOCUMENTS_URL + "/ptsb-flex-invest-maps3.pdf",
          },
          {
            name: "MAPS Balanced Key Information Document",
            href: process.env.REACT_APP_AWS_PRODUCT_DOCUMENTS_URL + "/ptsb-flex-invest-maps4.pdf"
          },
          {
            name: "MAPS Experienced Key Information Document",
            href:  process.env.REACT_APP_AWS_PRODUCT_DOCUMENTS_URL + "/ptsb-flex-invest-maps5.pdf",
          },
          {
            name: "FlexInvest Product Information & Terms and Conditions",
            href: "/pdfs/PTSB-FlexInvest_Product_Booklet_Terms_And_Conditions.pdf",
          },
          {
            name: "MAPS Conservative Pre-Contractual Disclosure",
            href: "/pdfs/P-MAP3_SFDR_Pre-Contractual_Disclosure.pdf",
          },
          {
            name: "MAPS Balanced Pre-Contractual Disclosure",
            href: "/pdfs/P-MAP4_SFDR_Pre-Contractual_Disclosure.pdf",
          },
          {
            name: "MAPS Experienced Pre-Contractual Disclosure",
            href: "/pdfs/P-MAP5_SFDR_Pre-Contractual_Disclosure.pdf",
          },
        ]
      : [
          {
            name: "MAPS Conservative Key Information Document",
            href: process.env.REACT_APP_AWS_PRODUCT_DOCUMENTS_URL + "/flex-invest-maps3.pdf"
          },
          {
            name: "MAPS Balanced Key Information Document",
            href: process.env.REACT_APP_AWS_PRODUCT_DOCUMENTS_URL + "/flex-invest-maps4.pdf",
          },
          {
            name: "MAPS Experienced Key Information Document",
            href: process.env.REACT_APP_AWS_PRODUCT_DOCUMENTS_URL + "/flex-invest-maps5.pdf",
          },
          {
            name: "FlexInvest Product Information & Terms and Conditions",
            href: "/pdfs/FlexInvest_Product_Booklet_Terms_And_Conditions.pdf",
          },
          {
            name: "MAPS Conservative Pre-Contractual Disclosure",
            href: "/pdfs/P-MAP3_SFDR_Pre-Contractual_Disclosure.pdf",
          },
          {
            name: "MAPS Balanced Pre-Contractual Disclosure",
            href: "/pdfs/P-MAP4_SFDR_Pre-Contractual_Disclosure.pdf",
          },
          {
            name: "MAPS Experienced Pre-Contractual Disclosure",
            href: "/pdfs/P-MAP5_SFDR_Pre-Contractual_Disclosure.pdf",
          },
        ],
  "/fund-fact-sheets": [
    {
      name: "MAPS Conservative Fund Fact Sheet",
      href:  process.env.REACT_APP_AWS_PRODUCT_DOCUMENTS_URL + "/fund-fact-sheet-conservative.pdf",
    },
    {
      name: "MAPS Balanced Fund Fact Sheet",
      href:  process.env.REACT_APP_AWS_PRODUCT_DOCUMENTS_URL + "/fund-fact-sheet-balanced.pdf",
    },
    {
      name: "MAPS Experienced Fund Fact Sheet",
      href:  process.env.REACT_APP_AWS_PRODUCT_DOCUMENTS_URL + "/fund-fact-sheet-experienced.pdf",
    },
  ],
}

