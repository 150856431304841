import styled from 'styled-components';

/*
  IE11 can't handle auto-placing grid elements so this wrapper takes the child's
  position and uses that to give IE an explicit grid position (also using the MS
  syntax specific)
  The extra layer also means that the items no longer stretch to fill the grid box
  on any browser so the flex properties mimic that
  NOTE: this component can be completely removed if IE support is dropped
 */
const GridElementWrapper = styled.div`
  display: flex;
  & > * {
    flex-grow: 1;
  }

  -ms-grid-row: ${({ position }) => position + 1};
  -ms-grid-column: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column-span: 1;

  @media (min-width: 700px) {
    -ms-grid-row: ${({ columns, position }) => Math.ceil((position + 1) / columns)};
    -ms-grid-column: ${({ columns, position }) => ((position + 1) % columns) + 1};
    -ms-grid-row-span: 1;
    -ms-grid-column-span: 1;
  }
`

export default GridElementWrapper;