import React from "react"
import styled from "styled-components"

const BluePage = styled.div`
  height: 100vh;
  width: 100wh;
  background-color: #5261ac;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const Content = styled.div`
  max-width: 700px;
  line-height: initial;
`

const Title = styled.div`
  font-size: 24px;
  @media (min-width: 700px) {
    font-size: 42px;
  }
`

const ErrCode = styled.div`
  font-size: 36px;
  font-weight: bold;
  @media (min-width: 700px) {
    font-size: 72px;
  }
`

const Description = styled.p``

//Error function
function NotFound() {
  return (
    <BluePage>
      <Content>
        <ErrCode>404</ErrCode>
        <Title>Page not found</Title>
        <Description>We couldn't find what you are looking for.</Description>
      </Content>
    </BluePage>
  )
}

export default NotFound
