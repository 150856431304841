export default function (content) {
  const supportPhoneNumber = process.env.REACT_APP_SUPPORT_NUMBER
  const emailAddress = process.env.REACT_APP_SUPPORT_EMAIL
  const appDomain = process.env.REACT_APP_DOMAIN_NAME
  const phoneLink = `[${supportPhoneNumber}](tel:${supportPhoneNumber.replace(
    / /g,
    ""
  )})`
  const emailLink = `[${emailAddress}](mailto:${emailAddress})`

  return content
    .replace(/{{SUPPORT_NUMBER}}/g, phoneLink)
    .replace(/{{SUPPORT_EMAIL}}/g, emailLink)
    .replace(/{{APP_DOMAIN}}/g, appDomain)
}
