import React from "react"
import styled from "styled-components"
import CookieBanner from "../../components/cookie-banner"
import Footer from "../../components/footer"
import Header from "../../components/header"

const PageTemplateWrapper = styled.main`
  align-items: stretch;
  background-color: #eeeff6;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const PageContentWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden; // fixes a large empty gap appearing in IE11
`

const InfoBox = styled.div`
  margin-top: 16px;
  margin-right: 9%;
  margin-left: 9%;
  background-color: white;
  text-align: center;
  border: 2px solid #3681d9;
  border-radius: 4px;
  padding: 1em;
  font-weight: bold;
  text-align: left;
  @media (max-width: 530px) {
    margin-right: 8%;
    margin-left: 8%;
    margin-bottom: 5px;
  }
  @media (min-width: 540px) and (max-width: 820px) {
    margin-right: 4%;
    margin-left: 4%;
  }
`
const InfoText = styled.label`
  font-color:#000000
  font-weight: bold;
`

function PageTemplate({ children }) {
  return (
    <PageTemplateWrapper>
      <Header />
      <InfoBox>
        <InfoText>Info: </InfoText>
        {process.env.REACT_APP_TENANT === "ptsb"
          ? "The advice given is from PTSB to invest in an Irish Life Assurance plan."
          : "The advice given is from Irish Life Financial Services to invest in an Irish Life Assurance plan."}
      </InfoBox>
      <PageContentWrapper>{children}</PageContentWrapper>
      <Footer />
      {process.env.REACT_APP_COOKIEBOT !== "on" && <CookieBanner />}
    </PageTemplateWrapper>
  )
}

export default PageTemplate
