import React from 'react';
import styled from 'styled-components';

const ContentCentred = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 25px;
`

const ContentWrapper = ({children}) => (
  <ContentCentred>
    {children}
  </ContentCentred>
);

export default ContentWrapper;
