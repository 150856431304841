import React, { useState, useEffect } from "react"
import styled from "styled-components"

import BreadCrumbTrail from "../../components/bread-crumb-trail"
import PageSection from "../../components/page-section"
import PageTemplate from "../../components/page-template"
import { getHelpCentreQuestions } from "../../utils/contentful"

const QuestionList = styled.div`
  margin: 0 auto 20px;
  max-width: 640px;
  @media (min-width: 700px) {
    margin-bottom: 55px;
  }
  h2 {
    font-family: "Assistant-ExtraBold";
    margin-bottom: 25px;
    @media (min-width: 700px) {
      margin-bottom: 50px;
    }
  }
`

const Question = styled.div`
  border-bottom: 1px solid #d6d7de;
  margin-bottom: 20px;
  padding-bottom: 20px;

  a {
    color: #333333;
    font-size: 15px;
    font-weight: bold;
  }
`

const ContactUs = styled.span`
  display: inline-block;
  font-size: 15px;
  text-align: center;
  width: 100%;
  a {
    color: #2096f3;
  }
`

const HelpCentreCategory = ({ match }) => {
  const [data, setData] = useState(null)

  const categorySlug = match.params.category

  useEffect(() => {
    if (
      navigator.userAgent === "ReactSnap" ||
      document.location.hostname === "localhost"
    ) {
      getHelpCentreQuestions(categorySlug)
        .then((response) => ({
          category: response.category,
          questions: response.questions,
        }))
        .then((state) => {
          setData(state)
          window.snapSaveState = () => ({ state })
        })
    } else {
      setData(window.state)
    }
  }, [categorySlug])

  if (!data) return null

  const crumbs = [
    {
      // Only set one as `back: true`
      back: true,
      name: "Help centre",
      href: "/help-centre",
    },
    {
      name: data.category.name,
    },
  ]

  return (
    <PageTemplate>
      <BreadCrumbTrail crumbs={crumbs} />
      <PageSection>
        <QuestionList>
          <h2>{data.category.name}</h2>
          {data.questions.map(({ name, slug }) => (
            <Question key={name}>
              <a href={`/help-centre/${categorySlug}/${slug}`}>{name}</a>
            </Question>
          ))}
        </QuestionList>
        <ContactUs>
          More questions? Call us on{" "}
          <a href={`tel:${process.env.REACT_APP_SUPPORT_NUMBER}`}>
            {process.env.REACT_APP_SUPPORT_NUMBER}
          </a>{" "}
          or email us at{" "}
          <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
            {process.env.REACT_APP_SUPPORT_EMAIL}
          </a>
        </ContactUs>
      </PageSection>
    </PageTemplate>
  )
}

export default HelpCentreCategory
