import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import ReactMarkdown, { renderers } from "react-markdown"
import PageSection from "../../components/page-section"
import PageTemplate from "../../components/page-template"
import FileDownloadLinkWrapper from "../../components/file-download-link"
import DropDownArrow from "../../assets/drop-down-arrow.svg"

import { pageContent, pageAttachments } from "../backup-pages-data"

const SidebarLinks = styled.div`
  border-bottom: 1px solid #d6d7de;
  border-top: 1px solid #d6d7de;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  @media (min-width: 700px) {
    border: none;
  }
`

const SidebarLink = styled.div`
  display: ${({ current, menuOpen }) =>
    current || menuOpen ? "block" : "none"};

  @media (min-width: 700px) {
    display: block;
  }

  a {
    color: #333;
    font-size: 12px;
    display: block;
    line-height: 17px;
    text-decoration: none;
    width: 100%;

    &:hover {
      font-weight: bold;
      text-decoration: none;
    }

    margin: 12px 0;
    padding-right: 15px;
    @media (min-width: 700px) {
      color: #706b70;
      font-size: 15px;
      font-weight: ${({ current }) => (current ? "bold" : "normal")};
      line-height: 21px;
      margin: 0 0 20px 0;
      padding-right: 0;
    }
  }
`

const OpenLink = styled.div`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 0;
  @media (min-width: 700px) {
    display: none;
  }
`

const ContentDetail = styled.div`
  h2 {
    font-family: "Assistant-ExtraBold";
    margin-bottom: 25px;
  }

  h3 {
    font-family: "Assistant-ExtraBold";
    font-size: 24px;
    margin-bottom: 1em;

    @media (min-width: 700px) {
      font-size: 36px;
    }
  }

  h4 {
    font-family: "Assistant-ExtraBold";
    font-size: 18px;
    margin-bottom: 1em;
  }

  h5 {
    font-family: "Assistant-Regular";
    font-size: 18px;
    color: #706b70;
    font-weight: normal;
    margin-bottom: 1em;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 2em;
  }

  th {
    padding: 1em;
    border: 1px solid #aaa;
    background-color: #d6d7de;
  }

  td {
    min-width: 30ch;
    padding: 1em;
    border: 1px solid #aaa;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }

  a {
    color: #2096f3;
  }
`

const BackupResponsiveLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 700px) {
    flex-direction: row;
    margin-bottom: 55px;
  }

  ${SidebarLinks} {
    @media (min-width: 700px) {
      flex: 0 0 200px;
      margin-right: 20px;
    }
  }

  ${ContentDetail} {
    @media (min-width: 700px) {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: auto;
    }
  }
`

const VerticalScroll = styled.div`
  overflow-x: scroll;
`

const sideMenuLinks = [
  { name: "About us", href: "/about-us" },
  { name: "Privacy notices", href: "/privacy-notices" },
  { name: "Terms of use", href: "/terms-of-use" },
  { name: "Terms of business", href: "/terms-of-business" },
  { name: "Disclaimer", href: "/disclaimer" },
  { name: "Product documents", href: "/product-documents" },
  { name: "Fund fact sheets", href: "/fund-fact-sheets" },
  { name: "Contact us", href: "/contact" },
]

function ResponsiveTableRenderer(content) {
  return <VerticalScroll>{renderers.table(content)}</VerticalScroll>
}

function CookiebotPolicy() {
  const ref = useRef()
  useEffect(() => {
    const placeholder = ref.current
    const script = document.createElement("script")
    script.id = "CookieDeclaration"
    script.src =
      "https://consent.cookiebot.com/2f2acb6d-8c6e-4935-b48e-07e7913fef44/cd.js"
    script.type = "text/javascript"
    placeholder.appendChild(script)
    return () => {
      placeholder.innerHTML = ""
    }
  }, [])
  return (
    <>
      <h2>Cookie policy</h2>
      <div ref={ref} />
    </>
  )
}

function Backup({ match }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)

  const currentPage = match.url
  const content = pageContent[currentPage]
  const attachedFiles = pageAttachments[currentPage]

  return (
    <div>
      <PageTemplate>
        <PageSection>
          <BackupResponsiveLayout>
            <SidebarLinks>
              {sideMenuLinks.map(({ name, href }) => (
                <SidebarLink
                  key={name}
                  current={currentPage === href}
                  menuOpen={menuOpen}
                >
                  <a href={href}>{name}</a>
                </SidebarLink>
              ))}
              <OpenLink onClick={toggleMenu}>
                <img alt="open" src={DropDownArrow} />
              </OpenLink>
            </SidebarLinks>
            <ContentDetail>
              {currentPage === "/cookies-policy" &&
              process.env.REACT_APP_COOKIEBOT === "on" ? (
                <CookiebotPolicy />
              ) : (
                <>
                  <ReactMarkdown
                    escapeHtml={false}
                    source={content}
                    renderers={{ table: ResponsiveTableRenderer }}
                  />
                  {attachedFiles &&
                    attachedFiles.map((attachment) => (
                      <FileDownloadLinkWrapper
                        key={attachment.name}
                        name={attachment.name}
                        href={attachment.href}
                      />
                    ))}
                </>
              )}
            </ContentDetail>
          </BackupResponsiveLayout>
        </PageSection>
      </PageTemplate>
    </div>
  )
}

export default Backup
