import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"

import PageTemplate from "../../components/page-template"
import BreadCrumbTrail from "../../components/bread-crumb-trail"

import articles from "../../content/learn-articles/"

const HeroImage = styled.img`
  display: block;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  @media (min-width: 750px) {
    padding: 25px;
  }
`

const Article = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: 100px;
`

const Title = styled.h1`
  font-size: 36px;
  line-height: 36px;
  padding: 50px 25px;
  margin: 0;
`

const Content = styled.div`
  padding: 0 25px;
  font-family: Arial, Helvetica, sans-serif;

  a {
    color: #2096f3;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  .warning-message {
    margin-top: 20px;
    p {
      border-radius: 5px;
      border: solid 2px #333;
      padding: 5px;
      font-weight: bold;
      margin: 0 0 15px;
    }
  }
  .tableData {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  .tableData td{
    height:80px;
    padding:12px;
  }
  .tableData th {
    border: 1px solid;
    padding: 8px;
  }
  .heroImg {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    @media (min-width: 750px) {
      padding: 25px;
    }
  }
`

const LearnArticle = ({ match }) => {
  const { article: articleSlug } = match.params
  const { title, image, content } = articles.find(
    (article) => article.slug === articleSlug
  )

  const crumbs = [
    {
      back: true,
      name: "Learn",
      href: "/learn",
    },
    {
      name: title,
    },
  ]

  return (
    <PageTemplate>
      <HeroImage src={image} />
      <Article>
        <BreadCrumbTrail crumbs={crumbs} />
        <Title>{title}</Title>
        <Content>
          <ReactMarkdown source={content} escapeHtml={false} />
        </Content>
      </Article>
    </PageTemplate>
  )
}

export default LearnArticle
