import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"

import { getHelpCentreQuestions } from "../../utils/contentful"

import BreadCrumbTrail from "../../components/bread-crumb-trail"
import FileDownloadLink from "../../components/file-download-link"
import PageSection from "../../components/page-section"
import PageTemplate from "../../components/page-template"
import interpolateMarkdown from "../../utils/interpolate-markdown"

import DropDownArrow from "../../assets/drop-down-arrow.svg"

const QuestionLinks = styled.div`
  border-bottom: 1px solid #d6d7de;
  border-top: 1px solid #d6d7de;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  @media (min-width: 700px) {
    border: none;
  }
`

const QuestionLink = styled.div`
  display: ${({ current, menuOpen }) =>
    current || menuOpen ? "block" : "none"};

  @media (min-width: 700px) {
    display: block;
  }

  a {
    color: #333;
    font-size: 12px;
    display: block;
    line-height: 17px;
    text-decoration: none;
    width: 100%;

    &:hover {
      font-weight: bold;
      text-decoration: none;
    }

    margin: 12px 0;
    padding-right: 15px;
    @media (min-width: 700px) {
      color: #706b70;
      font-size: 15px;
      font-weight: ${({ current }) => (current ? "bold" : "normal")};
      line-height: 21px;
      margin: 0 0 20px 0;
      padding-right: 0;
    }
  }
`

const OpenLink = styled.div`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 0;
  @media (min-width: 700px) {
    display: none;
  }
`

const QuestionDetail = styled.div`
  h2 {
    font-family: "Assistant-ExtraBold";
    margin-bottom: 25px;
  }

  h3 {
    font-family: "Assistant-ExtraBold";
    font-size: 24px;
    line-height: 1em;
    margin-bottom: 1em;

    @media (min-width: 700px) {
      font-size: 36px;
    }
  }

  h4 {
    font-family: "Assistant-ExtraBold";
    font-size: 18px;
    margin-bottom: 1em;
  }

  h5 {
    font-family: "Assistant-Regular";
    font-size: 18px;
    color: #706b70;
    font-weight: normal;
    margin-bottom: 1em;
  }

  a {
    color: #2096f3;
  }
`

const WarningBox = styled.div`
  margin-top: 1.5em;
  border: 2px solid #333;
  padding: 1em;
  font-weight: bold;
`

const QuestionDetailResponsiveLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 700px) {
    flex-direction: row;
    margin-bottom: 55px;
  }

  ${QuestionLinks} {
    @media (min-width: 700px) {
      flex: 1 0 200px;
      margin-right: 20px;
    }
  }

  ${QuestionDetail} {
    @media (min-width: 700px) {
      flex-grow: 1;
    }
  }
`

const HelpCentreQuestion = ({ match }) => {
  const [data, setData] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)

  const { category: categoryParam, question: questionParam } = match.params

  useEffect(() => {
    if (
      navigator.userAgent === "ReactSnap" ||
      document.location.hostname === "localhost"
    ) {
      getHelpCentreQuestions(categoryParam)
        .then((response) => {
          const question = response.questions.find(
            (question) => question.slug === questionParam
          )
          const questionLinks = response.questions.map((question) => ({
            name: question.name,
            href: `/help-centre/${question.category.slug}/${question.slug}`,
          }))

          return {
            question,
            questionLinks,
          }
        })
        .then((state) => {
          setData(state)
          window.snapSaveState = () => ({ state })
        })
    } else {
      setData(window.state)
    }
  }, [categoryParam, questionParam])

  if (!data || !data.question) {
    return null
  }

  const { question, questionLinks } = data
  const files = [] // TODO Add fiels to contentful
  const questionsWithWarningsBlock = [
    "what-is-smart-invest",
    "why-do-i-have-to-invest-for-5-years",
    "flexinvest-plan",
    "what-are-maps",
    "what-fund-is-the-conservative-portfolio-invested-in",
    "what-fund-is-the-balanced-portfolio-invested-in",
    "what-fund-is-the-experienced-portfolio-invested-in",
    "what-kind-of-returns-should-i-expect",
    "what-are-the-benefits-of-using-smart-invest",
  ]

  const showWarnings = questionsWithWarningsBlock.includes(question.slug)

  const crumbs = [
    {
      name: "Help centre",
      href: "/help-centre",
    },
    {
      // Only set one as `back: true`
      back: true,
      name: question.category.name,
      href: `/help-centre/${question.category.slug}`,
    },
    {
      name: question.name,
    },
  ]

  return (
    <PageTemplate>
      <BreadCrumbTrail crumbs={crumbs} />
      <PageSection>
        <QuestionDetailResponsiveLayout>
          <QuestionLinks>
            {questionLinks.map(({ name, href }) => (
              <QuestionLink
                key={name}
                current={name === question.name}
                menuOpen={menuOpen}
              >
                <a href={href}>{name}</a>
              </QuestionLink>
            ))}
            <OpenLink onClick={toggleMenu}>
              <img alt="open" src={DropDownArrow} />
            </OpenLink>
          </QuestionLinks>
          <QuestionDetail>
            <h2>{question.name}</h2>
            <ReactMarkdown source={interpolateMarkdown(question.body)} />
            {files &&
              files.map((file) => {
                const { name, href } = file
                return <FileDownloadLink name={name} href={href} />
              })}
            {showWarnings && (
              <>
                <WarningBox>
                  Warning: If you invest in this product you may lose some or
                  all of the money you invest.
                </WarningBox>
                <WarningBox>
                  Warning: The value of your investment may go down as well as
                  up.
                </WarningBox>
                <WarningBox>
                  Warning: This product may be affected by changes in currency
                  exchange rates.
                </WarningBox>
              </>
            )}
          </QuestionDetail>
        </QuestionDetailResponsiveLayout>
      </PageSection>
    </PageTemplate>
  )
}

export default HelpCentreQuestion
