import React from 'react';
import styled from 'styled-components';
import ContentBox from '../content-box';

import FileSVG from '../../assets/file.svg'

const FileDownloadLinkWrapper = styled(ContentBox)`
  align-items: flex-start;
  display: flex;
  margin-top: 20px;
  padding: 15px;

  a {
    color: #2096f3;
  }
`;

const FileName = styled.span`
  flex-grow: 1;
  margin: 0 10px;
`

const FileDownloadLink = ({ name, href }) => (
  <FileDownloadLinkWrapper>
    <img src={FileSVG} alt=''/>
    <FileName>{ name }</FileName>
    <a href={href} target='_blank' rel='noopener noreferrer'>Download</a>
  </FileDownloadLinkWrapper>
);

export default FileDownloadLink;
